import React from "react"
import "./style/stats.scss"
import ScrollAnimation from "react-animate-on-scroll"

const GlobalPandemic = () => {
  return ( 
    <ScrollAnimation duration={2} animateIn="fadeIn" animateOut="fadeOut">
      <div className="pandemic-container">
        <div class="pandemic-stats">
          <div>
            <p>No. of websites attacked daily</p>
            <span>30,000</span>
            <p>(Source: Web Arx Security)</p>
          </div>
          <div>
            <p>Percentage of small businesses targeted</p>
            <span>42%</span>
            <p>(Source: Embroker)</p>
          </div>
          <div>
            <p>Avg. cost to fix breaches for SMEs</p>
            <span>£8,460</span>
            <p>(Source: Gov.uk)</p>
          </div>
          <div>
            <p>No. of breached records in a month</p>
            <span>20 million</span>
            <p>(Source: IT Governance)</p>
          </div>
          <div>
            <p>No. of ransomware attacks per day</p>
            <span>4000</span>
            <p>(Source: FBI)</p>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default GlobalPandemic
