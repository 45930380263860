import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeroSection from "./heroSection"
import GlobalPandemic from "../cyberStats/globalPandemic"
import Cybercrime from "./cybercrime"
import Testimonials from "./testimonials"
import PostSection from "../postCards/postSection"
import Cta from "../cta/cta"
import { SectionHeadings } from "../helperComponents/helpers"
import "./style/index.scss"
import FeaturesCards from "../featuredCards/featuredCards"
import DashImage from "../../assets/images/dashboard-image.svg"
import FindingsImage from "../../assets/images/findings-image.svg"
import ScanImage from "../../assets/images/scan-company-image.svg"
import ScanVideo from "../../assets/images/feature2.mp4"
import FindingsVideo from "../../assets/images/feature3.mp4"
import AllVideo from "../../assets/images/feature1.mp4"
import TrainingImage from "../../assets/images/training.svg"
import ComplianceImage from "../../assets/images/questionnaire-compliance.svg"

const Index = () => {
   const blogData = useStaticQuery(graphql`
    query AllPostHome {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC },
        limit: 3
      ) {
        nodes {
          timeToRead
          excerpt
          frontmatter {
            author
            category
            date(formatString: "MMMM DD, yyyy")
            thumbnailAlt
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      allCategoriesJson {
        nodes {
          name
          color
        }
      }
      allAuthorsJson {
        nodes {
          name
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const pageData = [
    {
      subtitle: 'SECURI DETECT',
      title: 'Put your cybersecurity on autopilot',
      desc: "Don’t wait for a breach to happen! Securiwiser's automated monitoring helps you continuously assess and reduce the risks of potential cyberattacks, giving you peace of mind that your business is safe.",
      image: FindingsImage,
      video: FindingsVideo,
      imageAlt: 'cybersecurity rating',
      url: '/ratings',
      btnText: 'Learn more about securiDetect'
    },
    {
      subtitle: 'SECURI LEARN',
      title: 'Secure your team with bite-sized training',
      desc: "The best way to protect yourself against cyber threats is a resilient workforce. Securiwiser offers bite-sized cybersecurity training courses to strengthen user resilience and keep your staff up-to-date on the latest security trends.",
      image: TrainingImage,
      imageAlt: 'Security score',
      url: '/training',
      btnText: 'Learn more about securiLearn'
    },
    {
      subtitle: 'SECURI AUDIT',
      title: 'Stay cyber compliant with a few clicks',
      desc: "Don’t let compliance requirements become a nightmare. With Securiwiser, you get easy to use audit tools to help you meet security standards without having to worry about the complexity.",
      image: ComplianceImage,
      imageAlt: 'Cybersecurity posture',
      url: '/questionnaires',
      btnText: 'Learn more about securiAudit'
    }
  ];

  return (
    <div>
      <HeroSection />
      <SectionHeadings
        heading="It's become a Cyber Pandemic"
        subheading="Do something about it before it's too late."
      />
      <GlobalPandemic />
      <FeaturesCards data={pageData} />
      <Cybercrime />
      <Testimonials />
      <PostSection 
        postData={blogData.allMdx.nodes} 
        authorData={blogData.allAuthorsJson}
        catData={blogData.allCategoriesJson}
      />
      <Cta />
    </div>
  )
}

export default Index